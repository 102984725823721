import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for navigation
import styles from "./styles/News.module.css"; // Ensure the path is correct
import Layout from "./components/Layout"; // Ensure Layout component is properly set up

const About = () => {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://dr1f7r.me/about"
        title="Dr1f7r - Social Media Creator in Sydney Australia"
        description="Dr1f7r social media creator sharing lifestyle contents around the world. Explore exciting adventures, tasty eats, and lifestyle tips. Follow on TikTok, Instagram, Pinterest, and Facebook for more!"
        keywords="Dr1f7r, Social Media Creator Director"
      >
        <main className={styles.main2}>
      
 <h1>Welcome to Cr8tiv</h1>  
 <h2>We support you to live the dreams job</h2>
 
 
 <h3>Your Agency for Digital Innovation & Creator Growth</h3>

 <p>Since 2005, we have been at the forefront of digital media, content creation, and livestreaming, helping businesses and creators build meaningful connections with their audiences. As an official TikTok Creative Network Agency, we provide expert support for content creators and corporate businesses looking to enhance their digital presence and engagement.
 </p>
 <h3>Our Story</h3>

 <p>What started as a passion for web and multimedia design has grown into a full-service digital agency. From pioneering livestreaming technology for Surfit in 2006 to developing Socializa.co—an innovative social exchange platform with over 20,000 registered users—we've consistently pushed boundaries to enhance audience engagement and brand visibility.
 </p>
<h3>What We Do</h3>

<p>We specialise in:</p>
<ul>
<li>Web & Mobile App Development – Creating seamless digital experiences that engage and convert.</li>
<li>Livestreaming Solutions – Managing high-impact livestreams for businesses, events, and creators.</li>
<li>Digital Marketing & SEO – Optimising online presence with proven strategies.</li>
<li>Content Creation & Engagement – Helping businesses and influencers connect with their audiences effectively.</li>
<li>Social Media Growth – Supporting creators with audience expansion, engagement, and monetisation strategies.</li>
<li>TikTok Creative Network Agency Support – Providing creators and brands with industry-leading guidance to maximise their presence on TikTok.</li>
</ul>
<h3>Our Experience</h3>

<p>With nearly two decades in digital media, we’ve worked with global brands and startups alike. We’ve helped businesses launch successful marketing campaigns, livestream major events, and build platforms that drive engagement and growth.
</p>
<h3>Socializa.co & Global Impact</h3>

<p>One of our biggest achievements has been Socializa.co, a social exchange platform that helped over 20,000 users enhance engagement on platforms like Instagram, Facebook, and YouTube. By providing innovative tools for content sharing and audience growth, we’ve supported users worldwide in expanding their digital reach and improving engagement strategies.
</p>
<h3>Partnership with TikTok Creative Network Agency</h3>
<p>
As an official agency within the TikTok Creative Network, we provide expert support to content creators and corporate businesses looking to maximise their reach and monetisation potential. Our agency offers tailored strategies for:
</p>
<ul>
<li>Livestream Growth & Engagement – Helping creators and businesses optimise their TikTok LIVE sessions.</li>
<li>Content Strategy & Optimisation – Enhancing video performance for greater visibility.</li>
<li>Brand Partnerships & Monetisation – Connecting creators with businesses for collaborations and revenue opportunities.</li>
</ul>
<h3>Our Vision</h3>

<p>We are dedicated to empowering businesses and creators with cutting-edge digital solutions. Whether you're looking to elevate your online presence or expand your audience reach, we are here to support your journey as a trusted agency partner.</p>
<h3>Let’s Create & Innovate</h3>

<p>We believe in the power of storytelling, technology, and community. Join us as we continue shaping the future of digital content and social media engagement.</p>


        </main>
      </Layout>
    </div>
  );
};

export default About;