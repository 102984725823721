import { useRef } from "react";
import styles from "../styles/slider.module.css";

const BrandsSlider = () => {
  const sliderRef = useRef(null);

  const brands = [
    { src: "/brands/cars.jpg", alt: "Car Detailing Sydney", name: "Car Detailing Sydney", link: "https://cars.autosalonstudio.com/" },
    { src: "/brands/xform.png", alt: "Xform Media", name: "Xform Media", link: "https://xformmedia.com" },
    { src: "/brands/seoptimizer.png", alt: "SEOptimizer", name: "SEOptimizer", link: "https://seoptimizer.com" },
    { src: "/brands/d24.png", alt: "D24 Media", name: "D24 Media", link: "https://d24media.com" },
    { src: "/brands/cyberlab.png", alt: "Cyberlab", name: "Cyberlab", link: "https://cyberlabau.com" },
    { src: "/brands/feng-shui-master.png", alt: "Feng Shui Master", name: "Feng Shui Master", link: "https://pyfsc.com" },
    { src: "/brands/dr1f7r.png", alt: "Dr1f7r", name: "Dr1f7r", link: "https://dr1f7r.com" },
    { src: "/brands/guitarscales.png", alt: "Guitar Scales", name: "Guitar Scales", link: "https://guitarscales.live" },
  ];

  return (
    <div className={styles.newsblock}>
      <h2 className={styles.newsblockh2}>Brand Support</h2>
      <div className={styles.gridbrandsContainer}>
        <div className={styles.gridbrands} ref={sliderRef}>
          {brands.map((brand, index) => (
            <div key={index} className={styles.brandItem}>
              <a href={brand.link} target="_blank" rel="noopener noreferrer">
                <div className={styles.brandWrapper}>
                  <img src={brand.src} alt={brand.alt} className={styles.brandlogo} />
                  <p className={styles.brandName}>{brand.name}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandsSlider;
