import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import styles from "../styles/Layout.module.css";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ title, description, keywords, canonical, children }) => {
const location = useLocation();
const isHomepage = location.pathname === "/";

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="theme-color" content="#999" />
        <link rel="canonical" href={canonical} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/cr8tiv.svg" color="#999" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Helmet>

      {/* Conditionally render Header */}
      {!isHomepage && <Header />}

      {/* Main Content */}
  
        {children}
        <Footer />
 
    </div>
  );
};



export default Layout;