import React from "react";
import { Link } from "react-router-dom";
import Layout from "./components/Layout"; // Ensure this is the correct path
import styles from "./styles/News.module.css"; // Ensure this is the correct path

export default function NotFound() {
  return (
    <Layout
      title="Page Not Found"
      description="Sorry, the page you're looking for cannot be found. Return to the homepage to continue exploring Dr1f7r."
    >
      <div className={styles.notFoundContainer}>
        <h1 className={styles.centertext}>404 - Page Not Found</h1>
        <p className={styles.centertext}>
          Sorry, we couldn't find the page you were looking for. It might have been moved or no longer exists.
        </p>
        <p className={styles.centertext}>
          <Link
            to="/"
            title="Dr1f7r Social Media Content Creator Producer"
            className={styles.centertext}
          >
            Return to Cr8tiv Creative Creators Network Homepage
          </Link>
        </p>
      </div>
    </Layout>
  );
}
