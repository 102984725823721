import React from "react";
import styles from "../styles/Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
 {/*
 <div className={styles.footlinks}>Company info | Support Service | Our Strategy |  Brands  | How to Join | Contact us</div>*/}

      <p className={styles.copy}>© {new Date().getFullYear()} Cr8tiv Pty Ltd. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
