import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Main App Component
import "./styles/globals.css"; // Optional global styles (ensure this file exists)
import useGoogleAnalytics from "./hooks/useGoogleAnalytics";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);