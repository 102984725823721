import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Home.module.css";
import ft from "./styles/floating.css";
import Layout from "./components/Layout";
import { news } from "./data/watzup";
import NewsItem from "./components/NewsItem";
import BrandsSlider from './components/BrandsSlider';

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

useEffect(() => {
  const handleResize = () => setIsMobile(window.innerWidth <= 768);
  
  window.addEventListener("resize", handleResize);
  return () => window.removeEventListener("resize", handleResize);
}, []);
  const [floatingIcons, setFloatingIcons] = useState([
    { id: 1, src: "/like.svg", left: "40%" },
    { id: 2, src: "/contact.svg", left: "20%" },
    { id: 3, src: "/fingerhearts.svg", left: "50%" },
    { id: 4, src: "/share.svg", left: "20%" },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formType, setFormType] = useState("login"); // "login" or "signup"
  const generateRandomPositionAndSize = () => {
  const randomTop = `${Math.random() * 90 + 5}%`; // Random top position (5% to 90%)
  const randomLeft = `${Math.random() * 60 + 5}%`; // Random left position (5% to 90%)
  const randomSize = Math.random() * (50 - 30) + 30; // Random size between 30px and 50px

  return {
      top: randomTop,
      left: randomLeft,
      width: `${randomSize}px`,
      height: `${randomSize}px`,
      animationDelay: `${Math.random() * 8}s`, // Randomize the animation start delay
    };
  };

  useEffect(() => {
    // Update the positions and sizes of the floating icons with random values
    setFloatingIcons((prevIcons) =>
      prevIcons.map((icon) => ({
        ...icon,
        position: generateRandomPositionAndSize(),
      }))
    );
  }, []);
  
  return (
 
        <Layout
          title=" TikTok LIVE Agency Australia - Cr8tiv"
      
>
<div className={styles.homecontainer}>
      
        {floatingIcons.map((icon) => (
          <img
            key={icon.id}
            src={icon.src}
            alt="Floating Icon"
            className="floating-icon"
            style={{ left: icon.left,  top: icon.top, animationDelay: `${Math.random() * 5}s`, animationDuration: "6s" }}
          />
        ))}

          <main className={styles.main}>
              <Link to="/" title="Creative Creators Network">
             
                      <img
                        itemProp="image"
                        src="/cr8tiv.svg"
                        alt="Creative Creators Network"
                        width={800}
                        height={220}
                        loading="eager"
                        className={styles.cr8tiv}
                      />
                    </Link>
            <h1 className={styles.homehead}>
            Creative Network for TikTok LIVE Creators
            </h1>
            <h2 className={styles.h2sub}>
            
            Empower creators to reach new heights with expert support🎬, tailored marketing strategies, and top-tier production services. 🚀 Whether you're a musician, dancer, small business, or top-tier brand, our network helps you engage your audience, grow your reach, and maximize your impact—all for free! 🎁

Join us today and take your DREAMS to next level! ✨
            </h2>

            <div className={styles.gridsplash}>
            {/* Card Links */}
            {[
              {
                title: "Creative TikTok LIVE Agency Join Us",
                heading: "JOIN US",
                text: "It's completely free and we do not take your earnings.",
                link: "https://inapp.tiktokv.com/falcon/live_backstage/page/agency_detail/index.html?source=video&agency_scout_source=video_leads&title=Apply%20to%20join&ttba_uid=7481433411845293057",
              },
            ].map((item, idx) => (
              <div className={styles.homecard} key={idx}>
                {isMobile ? (
                  <button 
                  onClick={() => window.location.href = "https://inapp.tiktokv.com/falcon/live_backstage/page/agency_detail/index.html?source=video&agency_scout_source=video_leads&title=Apply%20to%20join&ttba_uid=7481433411845293057"} 
                  title="Creative TikTok LIVE Agency Join Us" 
                  className={styles.homecardmodal}
                >
                  <h3 className={styles.buttitle}>APPLY TODAY!</h3>
                  <p className={styles.butsub}>It's completely free and we do not take your earnings.</p>
                </button>
                ) : (
                  // Show QR Code on Desktop
                  <div className={styles.qrcontainer}>
                     <img
                        itemProp="image"
                        src="/cr8tiv-apply-now.png"
                        alt="Creative Creators Network"
                        width={200}
                        height={259}
                        loading="eager"
                        className={styles.qr}
                      />
                    <p className={styles.qrtext}>Scan to Apply</p>
                  </div>
                )}
              </div>
              ))}
            </div>

        
          </main></div>
          <div className={styles.textblock}>

<h2>Welcome to Cr8tiv</h2>

<p className={styles.textblockp}>A TikTok LIVE Agency and Creator Network based in Sydney, Australia, dedicated to helping TikTok LIVE creators elevate their streaming experience. We provide custom-tailored solutions to help creators reach their goals faster, offering personalised support every step of your journey. designed to help creators and brands of all kinds expand their reach and grow their audience. 
Whether you're a musician, dancer, small business, local brand, or top-tier company, we provide custom solutions to help you connect with a larger market and maximise your exposure.
Joining our Creator Network is completely free, and you keep 100% of the diamonds and help you grow audience and dollars you earn.</p>
<p><img
                        itemProp="image"
                        src="/tiktok-creators-network.svg"
                        alt="tiktok creators network partner"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.welcomelogo}
                      /></p>
</div>
<div className={styles.textblockjoin}>

<h2>Customise support</h2>

<p>At Cr8tiv, we elevate creators by providing personalised strategies, tailored support, professional attractive design ideas, incentives, and expert guidance to help you achieve success faster.
</p>
<div className={styles.grid}>
<div className={styles.card}>
<img
                        itemProp="image"
                        src="/free.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      /> <h3 className={styles.cardh3}>100% Free to Join</h3>
      <p className={styles.cardp}>Join our Creator Network with zero cost—no hidden fees.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/boost.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      /> <h3 className={styles.cardh3}>Gain More Visitors & Gifts</h3>
      <p className={styles.cardp}>Boost your visibility and increase your earnings through gifts and engagement.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/friends.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Creator Supporters</h3>
      <p className={styles.cardp}>Connect with a network of supporters who help grow your success.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/strategy.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Custom Marketing Strategies</h3>
      <p className={styles.cardp}>Grow your audience with tailored promotional plans.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/gifts-incentives.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Exclusive Incentives</h3>
      <p className={styles.cardp}>Unlock rewards and perks designed for creators.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/training.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Expert Guidance</h3>
      <p className={styles.cardp}>Get professional advice to enhance your streaming success.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/support.svg"
                        alt="Suport Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Dedicated Support</h3>
      <p className={styles.cardp}>Enjoy hands-on assistance whenever you need it.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/production.svg"
                        alt="Free join Creators Network"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Creative Production Team</h3>
      <p className={styles.cardp}>Access top-tier photographers, video editors, animators, and graphic designers.</p>
    </div>

    <div className={styles.card}>
    <img
                        itemProp="image"
                        src="/website-marketing.svg"
                        alt="Website Support"
                        width={200}
                        height={200}
                        loading="eager"
                        className={styles.supporticon}
                      />  <h3 className={styles.cardh3}>Website & Branding Assistance</h3>
      <p className={styles.cardp}>Get expert help in building your personal brand and online presence.</p>
    </div>
  </div>

</div>

<BrandsSlider />
 
        </Layout>     
      
  );
}