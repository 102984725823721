import React from "react";
import { Link } from "react-router-dom"; // Use react-router-dom for navigation
import styles from "./styles/News.module.css"; // Ensure the path is correct
import Layout from "./components/Layout"; // Ensure Layout component is properly set up

const About = () => {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://dr1f7r.me/connect"
        title="Dr1f7r - Social Media Creator in Sydney Australia"
        description="Dr1f7r social media creator sharing lifestyle contents around the world. Explore exciting adventures, tasty eats, and lifestyle tips. Follow on TikTok, Instagram, Pinterest, and Facebook for more!"
        keywords="Contact, Connect, Dr1f7r, Social Media Creator Director"
      >
        <main className={styles.main2}>
          {/* Page Header */}
          <h1 className={styles.centertext}>
         Let’s Connect & Create Together
          </h1>
          <h2 className={styles.centertext}>
          For Chill vibes Share Like and follow my social profiles. Send me a message.
          </h2>
          <div className={styles.grid}>
            {[
              {
                href: "https://tiktok.com/@dr1f7r",
                imgSrc: "/tiktok-icon.svg",
                imgAlt: "Dr1f7r Tik Tok",
                title: "Tik Tok",
              },
        
              {
                href: "https://www.instagram.com/dr1f7r/",
                imgSrc: "/instagram-icon.svg",
                imgAlt: "Dr1f7r Instagram",
                title: "Instagram",
              },
              {
                href: "https://pinterest.com/dr1f7r/",
                imgSrc: "/pinterest-icon.svg",
                imgAlt: "Dr1f7r Pinterest",
                title: "Pinterest",
              },
              {
                href: "https://www.beatport.com/chart/september-2018/530262?srsltid=AfmBOopRlQCyn2eQKeGPy4_g-YARb4sG2j46O7vbAQTCHTf-xMcHknuN",
                imgSrc: "/beatport-icon.svg",
                imgAlt: "Dr1f7r beatport",
                title: "beatport",
              },
              {
                href: "https://www.ultimate-guitar.com/u/dr1f7r",
                imgSrc: "/ultimate-guitar-icon.svg",
                imgAlt: "Ultimate Guitar Dr1f7r",
                title: "Ultimate Guitar",
              },
      
            ].map((item, idx) => (
              <a
                key={idx}
                href={item.href}
                className={styles.contactbox}
                target="_blank"
                rel="noopener"
              >
                <img
                  src={item.imgSrc}
                  alt={item.imgAlt}
                  width={120}
                  height={120}
                />
                <h3 className={styles.centertext}>{item.title}</h3>
              </a>
            ))}
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default About;