import React, { useState } from "react";
import styles from "./styles/News.module.css"; // Ensure the path is correct
import Layout from "./components/Layout"; // Ensure Layout is properly set up

const Portfolio = () => {
  const [selectedImage, setSelectedImage] = useState(
    "/merch/dr1f7r-short-sleeve-ts.png"
  );
  const [selectedSize, setSelectedSize] = useState("M");
  const [selectedColor, setSelectedColor] = useState("Black");
  const [showModal, setShowModal] = useState(false); // Modal state management

  const thumbnails = [
    "/merch/dr1f7r-short-sleeve-front.png",
    "/merch/dr1f7r-short-sleeve-back.png",
    "/merch/dr1f7r-short-sleeve-ts.png",
  ];

  const sizes = ["S", "M", "L", "XL"];
  const colors = ["Black", "White", "Gray"];

  const handleBuyNow = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false); // Adjust this based on your modal's state logic
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={styles.container}>
      <Layout
        canonical="https://d24.me/shop"
        title="Shop Dr1f7r Merchandise"
        description="Dr1f7r social media creator sharing lifestyle contents around the world. Explore exciting adventures, tasty eats, and lifestyle tips. Follow on TikTok, Instagram, Pinterest, and Facebook for more!"
        keywords="Dr1f7r, Social Media Creator Director"
      >
        <main className={styles.main2}>
          <h1 className={styles.centertext}>Dr1f7r Merchandise</h1>
          <h2 className={styles.centertext}>
            Hey friends! Want to help support the live while showing off some
            cool gear?
          </h2>
          <p className={styles.centertext}>
            🛒💥 Check out our exclusive Dr1f7r merchandise and rep the brand
            you love! Every purchase helps keep the fun going and supports the
            adventure! 🚀👕 Shop now and join the Dr1f7r community! 🎉
          </p>

          <div className={styles.product}>
            <div className={styles.imageGallery}>
              <img
                src={selectedImage}
                alt="Selected Merchandise"
                className={styles.mainImage}
              />
              <div className={styles.thumbnails}>
                {thumbnails.map((thumbnail, index) => (
                  <img
                    key={index}
                    src={thumbnail}
                    alt={`Thumbnail ${index + 1}`}
                    className={`${styles.thumbnail} ${
                      selectedImage === thumbnail ? styles.active : ""
                    }`}
                    onClick={() => setSelectedImage(thumbnail)}
                  />
                ))}
              </div>
            </div>

            <div className={styles.productDetails}>
              <h3 className={styles.Prodtitle}>Eat. Sleep. Dr1f7r.</h3>
              <p className={styles.Proddesc}>
                Eat. Sleep. Dr1f7r. Short Sleeves T-Shirt 100% Cotton
              </p>

              {/* Size Dropdown */}
              <div className={styles.dropdown}>
                <label htmlFor="size">Size:</label>
                <select
                  id="size"
                  value={selectedSize}
                  onChange={(e) => setSelectedSize(e.target.value)}
                >
                  {sizes.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>

              {/* Color Dropdown */}
              <div className={styles.dropdown}>
                <label htmlFor="color">Color:</label>
                <select
                  id="color"
                  value={selectedColor}
                  onChange={(e) => setSelectedColor(e.target.value)}
                >
                  {colors.map((color) => (
                    <option key={color} value={color}>
                      {color}
                    </option>
                  ))}
                </select>
              </div>

              {/* Buy Now Button */}
              <button className={styles.buyButton} onClick={handleBuyNow}>
                Gift Me One
              </button>
            </div>
          </div>
        </main>

        {/* Modal */}
        {showModal && (
  <div className={styles.modalOverlay}>
    <div className={styles.modal}>
      {/* Close Button */}
      <button className={styles.closeButton} onClick={handleCloseModal}>
        &times;
      </button>
      <h2>🎉 How to Earn Your Dr1f7r Free Gift! 🎁</h2>
      <p className={styles.modeltxt}>
        Follow these steps to support Dr1f7r and claim your exclusive gift:
      </p>
      <ul>
        <li>✨ Follow and Join My Team</li>
        <li>📺 Subscribe to my live stream</li>
        <li>🎁 Keep supporting the live stream with TTU!</li>
        <li>👆 100k Tap Tap</li>
      </ul>
      <button
        className={styles.tiktokButton}
        onClick={() => window.open("https://www.tiktok.com/@dr1f7r", "_blank")}
      >
        Gift Now
      </button>
    </div>
  </div>
)}
      </Layout>
    </div>
  );
};

export default Portfolio;
